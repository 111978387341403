<template>
    <div class="content-wrapper" :style="styles">
        <div v-if="showPresentationPreview" class="presentation-preview-wrapper">
            <div class="main-block">
                <div class="workspace">
                    <div class="canvas" :class="{'canvas__fullscreen' : isFullscreen}">
                        <Animation_controller
                            :boxed="true"
                            @init="acReady = true"
                            :resizeFlag="resizeFlag"
                            :animationPreview="false"
                            ref="animation_controller"
                            :vjsons="previewData.presentation"
                            @slideindexchanged="activeSlide = $event"
                        />
                    </div>
                </div>
            </div>

            <div v-if="previewData.allSlides.length > 0" class="bottom-bar">
                <a href="/" target="_blank" class="logo">
                    <img src="@/assets/images/logo.png" alt="SlideLab Logo"/>
                </a>
                <div class="handlers">
                    <button @click="prevElem()" :class="{'disabled-btn': atTimelineStart}">
                        <inline-svg width="18" height="16" :src="require('@/assets/icon/chevron-left.svg')"/>
                    </button>

                    <div class="counter">
                        <span>{{ currentFrameText }}</span>
                        <span> / </span>
                        <span>{{ previewData.allSlides.length }}</span>
                    </div>

                    <button @click="nextElem()" :class="{'disabled-btn': atTimelineEnd}">
                        <inline-svg width="18" height="16" :src="require('@/assets/icon/chevron-right.svg')"/>
                    </button>
                </div>
            </div>
        </div>
        <PageLoader v-if="pageLoader" :text="pageLoader"/>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import PageLoader from '@/components/PageLoader';
import Animation_controller from '@/components/AnimationController';
import AnimationControllerBar from '@/mixins/AnimationControllerBar';

export default {
    name: "PresentationEmbed",
    provide () {
        return {
            currentPage: this
        };
    },
    mixins: [AnimationControllerBar],
    components: { Animation_controller, PageLoader },
    data () {
        return {
            styles: {
                height: '100%'
            },
            activeSlide: 0,
            resizeFlag: false,
            isFullscreen: false,
            pageLoader: this.$t('presentationLoading'),
        };
    },
    computed: {
        ...mapState({
            previewData: state => state.viewer.previewData
        }),
        showPresentationPreview () {
            return this.previewData?.presentation?.meta && this.previewData?.allSlides?.length > 0;
        }
    },
    methods: {
        ...mapActions('viewer', [
            'getPresentationById',
        ]),
        resize () {
            this.resizeFlag = !this.resizeFlag;
        },
        nextElem () {
            this.$refs?.animation_controller.next();
        },
        prevElem () {
            this.$refs?.animation_controller.prev();
        },
    },
    async created () {
        if (!this.$route.params?.id) {
            throw new Error(this.$t('wrongLink'));
        }

        this.styles.height = this.$route?.params?.height || '100%';

        try {
            await this.getPresentationById(this.$route.params.id);
            this.pageLoader = null;
        } catch (e) {
            throw new Error(this.$t(e.message));
        }
    },
    mounted () {
        window.addEventListener('resize', () => this.resize());
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/preview.scss';

.content-wrapper {
    width: 100%;
    display: flex;
    min-width: 100%;
    align-items: center;
    justify-content: center;
}

.bottom-bar {
    position: relative;

    .logo {
        top: 50%;
        left: 10px;
        height: 60%;
        position: absolute;
        transform: translateY(-50%);

        img {
            height: 100%;
        }
    }
}

.presentation-preview-wrapper {
    display: flex;
    margin: 0 auto 70px;
    position: relative;
    background: #1c1e1f;
    align-items: stretch;
    flex-direction: column;

    width: 100vw;
    height: calc(100vw / 16 * 9);

    .main-block {
        flex: 1;
        top: 0px;
        left: 0px;
        z-index: 0;
        width: 100%;
        height: 100%;
        display: flex;
        cursor: pointer;
        position: absolute;
        box-sizing: content-box;
        border: 1px solid #3A3B41;

        .workspace {
            flex: 1;
            display: flex;
            position: relative;
            flex-direction: column;
            width: calc(100% - 230px);

            .canvas {
                flex: 1;
                display: flex;
                overflow: hidden;
                position: relative;
                background: #1c1e1f;

                .animation-container {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-color: #1d1f20;

                    .bg-trimmer {
                        z-index: 0;
                        width: 100%;
                        height: 100%;
                        display: block;
                        overflow: hidden;
                        position: absolute;

                        .backgrounds {
                            z-index: 0;
                            width: 100%;
                            height: 100%;
                            display: block;
                            position: absolute;
                        }
                    }

                    .slides-trimmer {
                        top: 50%;
                        left: 50%;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        position: absolute;
                        transform: translate(-50%, -50%);

                        .slides {
                            z-index: 1;
                            overflow: hidden;
                            position: relative;
                        }
                    }
                }
            }
        }
    }

    .bottom-bar {
        left: 0;
        right: -2px;
        height: 45px;
        bottom: -45px;
        display: flex;
        position: absolute;
        justify-content: center;
        background-color: #3A3B41;

        .handlers {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                border: none;
                display: flex;
                cursor: pointer;
                box-shadow: none;
                align-items: center;
                justify-content: center;
                background-color: transparent;

                svg {
                    opacity: 0.6;
                    fill: #ffffff;
                }
            }

            .counter {
                opacity: 0.6;
                color: #ffffff;
            }
        }
    }

    //@media (max-width: 768px) {
    //    width: 540px;
    //    height: calc(540px / 16 * 9);
    //}
    //
    //@media (max-width: 576px) {
    //    width: 90vw;
    //    height: calc(90vw / 16 * 9);
    //}
}
</style>

<i18n>
{
    "en": {
        "presentationLoading": "Loading..."
    },
    "ru": {
        "presentationLoading": "Загрузка..."
    }
}
</i18n>
